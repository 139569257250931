import axios from "axios";

/**
 * 总体统计
 * @returns {Promise<AxiosResponse<T>>}
 */
export const global = () => {
  return axios.get("/statistics/global");
};

/**
 * 泊位数据统计
 * @returns {Promise<AxiosResponse<T>>}
 */
export const slotNum = (page, pageSize) => {
  return axios.get("/statistics/slotNum", {
    params: {
      page, pageSize
    }
  });
};

export const amountweeklyRanking = (page, pageSize) => {
  return axios.get("/statistics/amountweeklyRanking",{
    params: {
      page, pageSize
    }
  })
}

export const parkingRecord24Hours = () => {
  return axios.get("/statistics/parkingRecord24Hours");
};


export const payOrderRecently = () => {
  return axios.get("/statistics/payOrderRecently");
};


export const userRecentlyAdded = () => {
  return axios.get("/statistics/userRecentlyAdded");
};
