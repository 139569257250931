import useMessage from "@/hooks/useMessage";
import { reactive, ref } from "vue";

const useDashboard = () => {
  const { showErr } = useMessage();
  const loading = ref(false);
  const slotList = reactive({list: []});
  const parkingList = reactive({list: []});
  const globalVo = reactive({
    totalAmount: "", //停车场总收入
    todayAmount: "", //今天总收入
    totalOrders: "", //订单总数
    todayOrders: "", //今日交易订单数
    totalUsers: "", //会员总数
    todayUsers: "", //今天新增会员数
    totalInCars: "", //在停车辆数
    todayOutCars: "", //今天离场车次
    feeSlotNum: "", //空闲泊位
    slotNumRate: "", //泊位周转率
  });

  const money = m => {
    if (typeof m !== "undefined") {
      return (m / 100).toFixed(2);
    }
    return "";
  };
  return {
    loading,
    globalVo,
    slotList,
    parkingList,
    showErr,
    money
  };
};

export default useDashboard;
