<template>
  <a-card title="近30天新增会员情况">
    <div id="addUsersRecentlyMyEcharts" style="height: 400px"></div>
  </a-card>
</template>

<script>
import { onMounted, ref, inject, onUnmounted } from "vue";
import { userRecentlyAdded } from "@/api/dashboardApi";
import useMessage from "@/hooks/useMessage";
import useDashboard from "@/hooks/useDashboard";

export default {
  name: "LeftAddUsersRencently",
  setup() {
    const loading = ref(false);
    const { showErr } = useMessage();
    const { money } = useDashboard();
    const echarts = inject("ec");
    let addUsersRecentlyMyEcharts = ref(null);
    onMounted(async () => {
      addUsersRecentlyMyEcharts.value = echarts.init(
        document.getElementById("addUsersRecentlyMyEcharts"),
      );
      addUsersRecentlyMyEcharts.value.setOption(options());
      loadData();
      window.onresize = () => {
        addUsersRecentlyMyEcharts.value.resize();
      };
    });
    onUnmounted(() => {
      addUsersRecentlyMyEcharts.value.dispose();
      addUsersRecentlyMyEcharts.value = null;
    });
    const options = () => {
      return {
        title: {
          // text: "24小时停车",
          textStyle: {
            // 文字颜色
            color: "rgb(3, 153, 204)",
            // 字体风格,'normal','italic','oblique'
            fontStyle: "normal",
            // 字体粗细 'normal','bold','bolder','lighter',100 | 200 | 300 | 400...
            fontWeight: "bold",
            // 字体系列
            fontFamily: "sans-serif",
            // 字体大小
            fontSize: 18,
          },
        },
        tooltip: {
          trigger: "item",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        color: ["#1E90FF"],
        legend: {
          x: "right",
          data: [
            {
              name: "新增会员",
              textStyle: {
                color: "#1E90FF",
              },
            },
          ],
          textStyle: {
            color: "white",
            fontSize: 14,
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: "rgb(0, 204, 255)", // 横坐标线的颜色
            },
          },
          axisLabel: {
            textStyle: {
              // color: "white", // 横坐标字体颜色
            },
          },
          axisTick: {
            interval: 1,
          },
          type: "category",
          data: [],
        },
        yAxis: {
          type: "value",
          splitLine: {
            // 分隔线
            // show: false,
          },
          axisLine: {
            lineStyle: {
              color: "rgb(0, 204, 255)", // 纵坐标线的颜色
            },
          },
          axisLabel: {
            textStyle: {
              // color: "white", // 纵坐标字体颜色
            },
          },
        },
        series: [],
      };
    };
    const setOptions = (usersNumberList, xData) => {
      let usersNumberRecord = {
        name: "新增会员",
        type: "line",
        smooth: "true",
        data: usersNumberList,
      };
      let option = {
        series: [usersNumberRecord],
        xAxis: {
          data: xData.sort((a, b) => {
            return b < a ? 1 : -1; // 升序
          }),
        },
      };
      addUsersRecentlyMyEcharts.value.setOption(option);
    };
    const loadData = async () => {
      loading.value = true;
      try {
        let r = await userRecentlyAdded();
        let xData = [];
        let usersNumberList = [];
        r.data.forEach(item => {
          xData.push(item.date);
          let p1 = {
            name: item.date,
            value: item.userNumber,
          };
          usersNumberList.push(p1);

          usersNumberList.sort((a,b)=>{
            return a.name > b.name ? 1 : -1
          })
          setOptions(usersNumberList, xData);
        });

        loading.value = false;
      } catch (e) {
        loading.value = false;
        showErr(e);
      }
    };

    return {
      echarts,
      options,
      loading,
      loadData,
      setOptions,
      money,
      addUsersRecentlyMyEcharts,
    };
  },
};
</script>
